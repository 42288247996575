import React, { FC } from 'react';
import NeighborhoodMap from '../../components/NeighborhoodMap/NeighborhoodMap';

const GNeighborhoodMap: FC = () => {
  return (
    <NeighborhoodMap>
      <></>
    </NeighborhoodMap>
  );
};

export default GNeighborhoodMap;

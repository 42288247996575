import React from 'react';
import { WeatherIcon } from '../../hooks/weather/weather';

export const GWeatherIcon: React.FC = () => {
  return (
    <div className="flex h-full flex-col gap-8 overflow-auto p-4">
      <h2>Default icons</h2>
      <div className="grid grid-flow-col grid-rows-2 place-items-center items-center gap-x-6 gap-y-2 overflow-auto text-center">
        <WeatherIcon symbol="Clear sky" />
        <h4>Clear sky</h4>

        <WeatherIcon symbol="Nearly clear sky" />
        <h4>Nearly clear sky</h4>

        <WeatherIcon symbol="Variable cloudiness" />
        <h4>Variable cloudiness</h4>

        <WeatherIcon symbol="Halfclear sky" />
        <h4>Halfclear sky</h4>

        <WeatherIcon symbol="Cloudy sky" />
        <h4>Cloudy sky</h4>

        <WeatherIcon symbol="Overcast" />
        <h4>Overcast</h4>

        <WeatherIcon symbol="Fog" />
        <h4>Fog</h4>

        <WeatherIcon symbol="Light rain showers" />
        <h4>Light rain showers</h4>

        <WeatherIcon symbol="Moderate rain showers" />
        <h4>Moderate rain showers</h4>

        <WeatherIcon symbol="Heavy rain showers" />
        <h4>Heavy rain showers</h4>

        <WeatherIcon symbol="Thunderstorm" />
        <h4>Thunderstorm</h4>

        <WeatherIcon symbol="Light sleet showers" />
        <h4>Light sleet showers</h4>

        <WeatherIcon symbol="Moderate sleet showers" />
        <h4>Moderate sleet showers</h4>

        <WeatherIcon symbol="Heavy sleet showers" />
        <h4>Heavy sleet showers</h4>
      </div>
      <div className="grid grid-flow-col grid-rows-2 place-items-center items-center gap-x-6 gap-y-2 overflow-auto text-center">
        <WeatherIcon symbol="Light snow showers" />
        <h4>Light snow showers</h4>

        <WeatherIcon symbol="Moderate snow showers" />
        <h4>Moderate snow showers</h4>

        <WeatherIcon symbol="Heavy snow showers" />
        <h4>Heavy snow showers</h4>

        <WeatherIcon symbol="Light rain" />
        <h4>Light rain</h4>

        <WeatherIcon symbol="Moderate rain" />
        <h4>Moderate rain</h4>

        <WeatherIcon symbol="Heavy rain" />
        <h4>Heavy rain</h4>

        <WeatherIcon symbol="Thunder" />
        <h4>Thunder</h4>

        <WeatherIcon symbol="Light sleet" />
        <h4>Light sleet</h4>

        <WeatherIcon symbol="Moderate sleet" />
        <h4>Moderate sleet</h4>

        <WeatherIcon symbol="Heavy sleet" />
        <h4>Heavy sleet</h4>

        <WeatherIcon symbol="Light snowfall" />
        <h4>Light snowfall</h4>

        <WeatherIcon symbol="Moderate snowfall" />
        <h4>Moderate snowfall</h4>

        <WeatherIcon symbol="Heavy snowfall" />
        <h4>Heavy snowfall</h4>

        <WeatherIcon symbol="Unknown" />
        <h4>Unknown</h4>
      </div>
      <h2>Big</h2>
      <WeatherIcon symbol="Halfclear sky" className="h-48 w-48" />
    </div>
  );
};

export default GWeatherIcon;

import React, { FC } from 'react';
import GalleryWrapper from './GalleryWrapper';
import TypographyTheming from './GalleryOnlyComponents/TypographyTheming';
import GalleryContainer from './GalleryContainer';
import ColorTheming from './GalleryOnlyComponents/ColorTheming';
import GTenantListItem from './GalleryOnlyComponents/GTenantListItem';
import CoveringLoadingScreen from '../components/CoveringLoadingScreen';
import GAlertComponent from './GalleryOnlyComponents/GAlertComponent';
import GTenantList from './GalleryOnlyComponents/GTenantList';
import GPublicTransitQuickInfo from './GalleryOnlyComponents/GDepartsSoonList';
import TenantRegistryView from '../views/TenantRegistryView';
import InstallView from '../views/InstallView';
import PublicTransitView from '../views/PublicTransitView';
import GPublicTransitList from './GalleryOnlyComponents/GStopDeparturesList';
import GErrorBar from './GalleryOnlyComponents/GErrorBar';
import GFilesView from './GalleryOnlyComponents/GFilesView';
import GNeighborhoodMap from './GalleryOnlyComponents/GNeighborhoodMap';
import GWeatherIcon from './GalleryOnlyComponents/GWeatherIcon';
import GWeatherWidget from './GalleryOnlyComponents/GWeatherWidget';
import WeatherView from '../views/WeatherView';
import NavPanel from '../components/NavPanel/NavPanel';

const Gallery: FC = () => {
  return (
    <GalleryContainer enableVerticalMode>
      <GalleryWrapper g="Theme" description="TypographyTheming">
        <TypographyTheming />
      </GalleryWrapper>
      <GalleryWrapper g="Theme" description="ColorTheming">
        <ColorTheming />
      </GalleryWrapper>
      <GalleryWrapper g="Tenant registry" description="Tenant List Item">
        <GTenantListItem />
      </GalleryWrapper>
      <GalleryWrapper g="Tenant registry" description="Tenant List">
        <GTenantList />
      </GalleryWrapper>
      <GalleryWrapper g="Tenant registry" description="Alert component">
        <GAlertComponent />
      </GalleryWrapper>
      <GalleryWrapper g="Views" description="Tenant registry view">
        <TenantRegistryView />
      </GalleryWrapper>
      <GalleryWrapper g="Views" description="Install view">
        <InstallView />
      </GalleryWrapper>
      <GalleryWrapper g="Views" description="Public transit view">
        <PublicTransitView />
      </GalleryWrapper>
      <GalleryWrapper g="Views" description="Weather View">
        <WeatherView />
      </GalleryWrapper>
      <GalleryWrapper g="Views" description="Files view">
        <GFilesView />
      </GalleryWrapper>
      <GalleryWrapper description="No params" g="Loading screen">
        <CoveringLoadingScreen />
      </GalleryWrapper>
      <GalleryWrapper description="Custom message" g="Loading screen">
        <CoveringLoadingScreen message="Custom message" />
      </GalleryWrapper>
      <GalleryWrapper description="Custom message, progress" g="Loading screen">
        <CoveringLoadingScreen message="Custom message" progressBar />
      </GalleryWrapper>
      <GalleryWrapper description="Just progress" g="Loading screen">
        <CoveringLoadingScreen progressBar />
      </GalleryWrapper>
      <GalleryWrapper description="Nav Panel">
        <NavPanel />
      </GalleryWrapper>
      <GalleryWrapper
        g="Public Transit"
        description="Public Transit Quick Info"
      >
        <GPublicTransitQuickInfo />
      </GalleryWrapper>
      <GalleryWrapper g="Public Transit" description="Transit list">
        <GPublicTransitList />
      </GalleryWrapper>
      <GalleryWrapper description="ErrorBar">
        <GErrorBar />
      </GalleryWrapper>
      <GalleryWrapper g="Map" description="NeighborhoodMap">
        <GNeighborhoodMap />
      </GalleryWrapper>
      <GalleryWrapper g="Weather" description="Weather Icon">
        <GWeatherIcon />
      </GalleryWrapper>
      <GalleryWrapper g="Weather" description="Weather Widget">
        <GWeatherWidget />
      </GalleryWrapper>
    </GalleryContainer>
  );
};

export default Gallery;

import React, { FC, useMemo } from 'react';
import TenantListItem from '../../components/TenantComponents/TenantListItem';
import { getTenantsWithFloor } from '../../utils/TenantUtils';
import { ddUnits } from '../dummydata/ddUnits';
import { ddFloors } from '../dummydata/ddFloors';

const GTenantListItem: FC = () => {
  const tenants = useMemo(() => getTenantsWithFloor(ddUnits, ddFloors), []);

  if (!tenants) {
    return <div>No tenants available</div>;
  }

  return (
    <div className="flex flex-col gap-8">
      <h2>Sorting by first name</h2>
      <TenantListItem tenant={tenants[0]} sort="name" />
      <TenantListItem tenant={tenants[1]} sort="name" />
      <TenantListItem tenant={tenants[tenants.length - 1]} sort="name" />
      <h2>Sorting by last name</h2>
      <TenantListItem tenant={tenants[0]} sort="last_name" />
      <TenantListItem tenant={tenants[1]} sort="last_name" />
      <TenantListItem tenant={tenants[tenants.length - 1]} sort="last_name" />
      <h2>Sorting by floor</h2>
      <TenantListItem tenant={tenants[0]} sort="floor" />
      <TenantListItem tenant={tenants[1]} sort="floor" />
      <TenantListItem tenant={tenants[tenants.length - 1]} sort="floor" />
    </div>
  );
};

export default GTenantListItem;

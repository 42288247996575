import React, { FC, useEffect, useMemo, useState } from 'react';
import TenantToolbar from '../../components/TenantComponents/TenantToolbar';
import TenantList from '../../components/TenantComponents/TenantList';
import {
  getTenantsWithFloor,
  sortAndGroupTenants,
  TenantGrouping,
  TenantListSortOption,
} from '../../utils/TenantUtils';
import { ddUnits } from '../dummydata/ddUnits';
import { ddFloors } from '../dummydata/ddFloors';

const GTenantList: FC<{ vertical?: boolean }> = () => {
  const [currSortGroup, setCurrSortGroup] = useState<TenantGrouping[]>([]);
  const [currSorting, setCurrSorting] =
    useState<TenantListSortOption>('last_name');

  const tenants = useMemo(() => getTenantsWithFloor(ddUnits, ddFloors), []);

  const handleSortClick = (sort: TenantListSortOption): void => {
    setCurrSortGroup(sortAndGroupTenants(tenants, sort));
    setCurrSorting(sort);
  };

  useEffect(() => {
    setCurrSortGroup(sortAndGroupTenants(tenants, currSorting));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currSorting]);

  return (
    <div className="h-full">
      <h2 className="p-10">Tenant List</h2>
      <TenantToolbar onSortClick={handleSortClick} sort={currSorting} />
      <TenantList tenantGroups={currSortGroup} sort={currSorting} />
    </div>
  );
};

export default GTenantList;

import React, { FC } from 'react';

const colors = ['blue', 'green', 'red', 'yellow', 'gray', 'white'];

const ColorTheming: FC = () => {
  return (
    <div className="m-4 grid grid-cols-5 gap-8">
      <p className="font-bold">color</p>
      <p>main</p>
      <p>dark</p>
      <p>medium</p>
      <p>light</p>
      {colors.map((c) => (
        <React.Fragment key={c}>
          <span>{c}</span>
          <div
            className={`grow rounded border border-gray-light bg-${c} shadow`}
          />
          <div
            className={`grow rounded border border-gray-light bg-${c}-dark shadow`}
          />
          <div
            className={`grow rounded border border-gray-light bg-${c}-medium shadow`}
          />
          <div
            className={`grow rounded border border-gray-light bg-${c}-light shadow`}
          />
        </React.Fragment>
      ))}
      <span>linkColor</span>
      <div className="col-span-4 grow rounded border border-gray-light bg-linkColor shadow" />
    </div>
  );
};

export default ColorTheming;

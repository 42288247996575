import React, { FC } from 'react';
import WeatherWidget from '../../components/Weather/WeatherWidget';
import { ddWeatherForecast } from '../dummydata/ddWeather';

const GWeatherWidget: FC = () => {
  return (
    <div className="gap flex flex-col gap-8 p-4">
      <h2>Weather Widgets</h2>
      <div className="flex flex-row gap-8">
        {ddWeatherForecast.map((w) => (
          <WeatherWidget weather={w} key={w.temperature} />
        ))}
      </div>
    </div>
  );
};

export default GWeatherWidget;

import React, { FC } from 'react';
import StopDeparturesList from '../../components/PublicTransit/StopDeparturesList';
import { usePublicTransit } from '../../store/usePublicTransit';
import { usePublicTransitStops } from '../../hooks/usePublicTransitStops';

const GPublicTransitList: FC = () => {
  const { data: stops } = usePublicTransitStops();
  const { departures } = usePublicTransit(({ departures }) => ({
    departures,
  }));

  if (!stops || Object.keys(stops).length === 0) {
    return null;
  }

  return (
    <div className="flex h-full flex-col gap-8 overflow-auto">
      <h2>Basic list</h2>
      <StopDeparturesList
        label={stops[0].name}
        departures={departures[stops[0].id].slice(0, 10)}
      />
      <h2>Slim</h2>
      <div className="w-96">
        <StopDeparturesList
          label={stops[0].name}
          departures={departures[stops[0].id].slice(0, 10)}
        />
      </div>
      <h2>No transits</h2>
      <StopDeparturesList departures={[]} label={stops[1].name} />
    </div>
  );
};

export default GPublicTransitList;

import React, { FC } from 'react';

const TypographyTheming: FC = () => {
  return (
    <div className="m-2 flex flex-col gap-8">
      <div>
        <p className="header1">header1</p>
        <p className="header1">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="header2">header2</p>
        <p className="header2">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="header3">header3</p>
        <p className="header3">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="header2">header2</p>
        <p className="header2">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="body1">body1</p>
        <p className="body1">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="body2">body2</p>
        <p className="body2">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="button">button</p>
        <p className="button">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="caption">caption</p>
        <p className="caption">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
      <div>
        <p className="font-small">font-small</p>
        <p className="font-small">
          Yxmördaren Julia Blomqvist på fäktning i Schweiz
        </p>
      </div>
    </div>
  );
};

export default TypographyTheming;

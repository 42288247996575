import React, { useState } from 'react';
import ErrorBar from '../../components/ErrorBar';
import { DateTime } from 'luxon';

interface Variant {
  title: string;
  value?: string;
}

const variants: Variant[] = [
  { title: 'No error' },
  { title: 'Short error', value: 'Error' },
  {
    title: 'Long error',
    value:
      'This is a very long error message that might or might not trigger wordwrap depending on the browser size or screen resolution. This could also wrap more than two lines which would make it clip',
  },
];

export const GErrorBar: React.FC = () => {
  const [toggleError, setToggleError] = useState<string>();
  return (
    <div className="m-4 flex flex-col gap-8">
      {variants.map((err) => (
        <section
          key={err.title}
          className="flex flex-col border border-gray-medium"
        >
          <h3 className="m-2">{err.title}</h3>
          <ErrorBar value={err.value} />
        </section>
      ))}
      <section className="flex flex-col border border-gray-medium">
        <div className="m-2 flex gap-8">
          <h3>Toggle error</h3>
          <button
            className="rounded p-2 btn-green"
            onClick={() =>
              setToggleError(
                toggleError
                  ? undefined
                  : 'There was an error ' + DateTime.now().toISOTime(),
              )
            }
          >
            Toggle
          </button>
          <button
            className="rounded p-2 btn-green"
            onClick={() =>
              setToggleError('There was an error ' + DateTime.now().toISOTime())
            }
          >
            Update
          </button>
        </div>
        <ErrorBar value={toggleError} />
      </section>
    </div>
  );
};

export default GErrorBar;

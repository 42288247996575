import React, { cloneElement, FC, ReactElement } from 'react';

export interface GalleryWrapperProps {
  children: ReactElement;
  description: string;

  bg?: string;
  /** Convenience for settin `boxProps: {{ sx {{ p: <number>; }}}}`. Defaults to 2. */
  /** If provided overrides the global Gallery vertical mode setting. */
  verticalMode?: boolean;
  /** Used to group components in the gallery list together. Defaults to '~'. */
  g?: string;
}

const GalleryWrapper: FC<GalleryWrapperProps> = ({
  children,
  // boxProps,
  verticalMode,
  bg,
}) => {
  return (
    <div
      className="h-full w-full overflow-auto"
      style={{
        background: bg || 'white',
      }}
    >
      {cloneElement(children, { vertical: verticalMode })}
    </div>
  );
};

export default GalleryWrapper;

import React, {
  cloneElement,
  FC,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import { GalleryWrapperProps } from './GalleryWrapper';

interface GalleryContainerProps {
  children: ReactElement | ReactElement[];
  enableVerticalMode?: boolean;
}

const GalleryContainer: FC<GalleryContainerProps> = ({ children }) => {
  const [selectedCompIndex, setSelectedComponentIndex] = useState<number>(-1);

  const [verticalMode, setVerticalMode] = useState<boolean>(false);
  const [verticalWidth, setVerticalWidth] = useState<number>(1080);
  const [verticalWidthInput, setVerticalWidthInput] = useState<string>('');

  const { childArray, childrenWithDividers } = useMemo(() => {
    const processedChildren: {
      childArray: ReactElement<Omit<GalleryWrapperProps, 'children'>>[];
      childrenWithDividers: (
        | {
            type: 'child';
            child: ReactElement<Omit<GalleryWrapperProps, 'children'>>;
            index: number;
          }
        | {
            type: 'divider';
            description: string;
          }
      )[];
    } = {
      childArray: [],
      childrenWithDividers: [],
    };
    if (!children || typeof children !== 'object') {
      return processedChildren;
    }

    const childArray: ReactElement<Omit<GalleryWrapperProps, 'children'>>[] =
      !Array.isArray(children) ? [children] : children;

    for (const child of childArray) {
      if (!child.props.description) {
        continue;
      }
      processedChildren.childArray.push(child);
    }
    processedChildren.childArray.sort((a, b) => {
      const groupA = a.props.g || '~';
      const groupB = b.props.g || '~';
      if (groupA === groupB) {
        return 0;
      }
      return groupA > groupB ? 1 : -1;
    });

    let prevGroup =
      processedChildren.childArray[processedChildren.childArray.length - 1]
        .props.g;

    processedChildren.childArray.forEach((c, i) => {
      const group = c.props.g;
      if (prevGroup !== group) {
        //A new group has been found, insert a divider.
        processedChildren.childrenWithDividers.push({
          type: 'divider',
          description: group || '~',
        });
        prevGroup = group;
      }

      processedChildren.childrenWithDividers.push({
        type: 'child',
        child: c,
        index: i,
      });
    });

    return processedChildren;
  }, [children]);

  const isSelectedVertical = useMemo(() => {
    if (selectedCompIndex === -1) {
      return verticalMode;
    }
    return typeof childArray[selectedCompIndex].props.verticalMode === 'boolean'
      ? childArray[selectedCompIndex].props.verticalMode
      : verticalMode;
  }, [childArray, selectedCompIndex, verticalMode]);

  if (childArray.length === 0) {
    return <div>No components to show.</div>;
  }

  return (
    <div className="flex h-full w-full overflow-hidden bg-gray-medium">
      <nav className="h-full min-w-[300px] max-w-[300px] border-r-2 border-gray-medium bg-white shadow-xl">
        <header className="flex gap-2 border-b border-gray-medium p-2 shadow-lg">
          <input
            type="checkbox"
            id="vertical"
            onChange={(e) => setVerticalMode(e.target.checked)}
          />
          <label htmlFor="vertical" className="text-xs">
            Vertical mode
          </label>
          <input
            id="res"
            className="ml-4 w-[50px] text-xs"
            type="number"
            value={verticalWidthInput || verticalWidth}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (isFinite(value)) {
                setVerticalWidthInput(value.toString());
                setVerticalWidth(value);
              } else {
                setVerticalWidthInput(e.target.value);
              }
            }}
          />
          <label htmlFor="res" className="text-xs">
            px
          </label>
        </header>
        <ul className="mx-2 flex flex-col gap-1">
          {childrenWithDividers.map((c, i) =>
            c.type === 'divider' ? (
              <li className="button mt-2" key={`divider-${i}`}>
                {c.description}
              </li>
            ) : (
              <li className="ml-2" key={`gallery-link-${i}`}>
                <button
                  className={`w-full text-left text-sm font-medium ${
                    selectedCompIndex === c.index
                      ? 'border-r-2 border-green-dark font-bold text-green-dark'
                      : 'text-gray'
                  }`}
                  onClick={() => setSelectedComponentIndex(c.index)}
                >
                  {c.child.props.description}
                </button>
              </li>
            ),
          )}
        </ul>
      </nav>
      <main
        className="h-full w-full overflow-hidden bg-white shadow-2xl"
        style={{
          width: verticalMode ? `${verticalWidth}px` : '',
          minWidth: verticalMode ? `${verticalWidth}px` : '',
          maxWidth: verticalMode ? `${verticalWidth}px` : '',
        }}
      >
        {selectedCompIndex === -1 ? (
          <h2 className="m-8">No component selected</h2>
        ) : (
          cloneElement(childArray[selectedCompIndex], {
            verticalMode: isSelectedVertical,
          })
        )}
      </main>
      <aside className="grow bg-gradient-to-r from-gray to-gray-medium" />
    </div>
  );
};

export default GalleryContainer;

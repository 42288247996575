import React from 'react';
import AttachmentsView from '../../views/AttachmentsView';
import { useAttachments } from '../../hooks/useAttachments';

export const GFilesView: React.FC = () => {
  const { data } = useAttachments();

  return (
    <AttachmentsView
      title="Files View"
      attachments={data?.['energy-declaration-ovk']}
    />
  );
};

export default GFilesView;

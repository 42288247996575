import React, { FC, useState } from 'react';
import AlertComponent from '../../components/AlertComponent';

const normalMessage = { message: 'Planerat strömavbrott 17/4 13:00-15:00' };

const mediumMessage = {
  message:
    'Vi har planerat att stänga av eran ström den 17 april mellan följande tider: 13:00 - 15:00',
};

const longAlertMessage = {
  message:
    'Consectetur velit elit consectetur officia anim aute eiusmod consectetur.Consequat voluptate labore adipisicing mollit anim id.' +
    'Consequat voluptate labore adipisicing mollit anim id',
};

const GAlertComponent: FC<{ vertical?: boolean }> = () => {
  const [text, setText] = useState<string>('');
  return (
    <div className="m-2 flex flex-col gap-8">
      <h2>{normalMessage.message.length} characters:</h2>
      <AlertComponent announcements={[normalMessage]} />
      <h2>{mediumMessage.message.length} characters:</h2>
      <AlertComponent announcements={[mediumMessage]} />

      <h2>{longAlertMessage.message.length} characters:</h2>
      <AlertComponent announcements={[longAlertMessage]} />

      <input
        type="text"
        className="rounded border border-gray-light p-2"
        placeholder="Input text to test typography breakpoints"
        onChange={(event) => setText(event.target.value)}
      />
      <h2>{text.length} characters:</h2>
      <AlertComponent announcements={[{ message: text }]} />
    </div>
  );
};

export default GAlertComponent;
